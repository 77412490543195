import { useGetIntercomSettingsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button } from "@simplicate/ui";
import { Module, asModule } from "@simplicate/utils";
import classnames from "classnames";
import { useIntercom } from "react-use-intercom";
import styles from "./HelpDropdown.module.scss";

const PRODUCT_PORTAL_URL = "//portal.productboard.com/vmj5ra15wh8kawwjx8ezetsn";
const SUPPORT_PAGE_BASE_URL = "//support.simplicate.nl/";
const SUPPORT_URLS: Record<Module, string> = {
  "business-intelligence": SUPPORT_PAGE_BASE_URL + "nl/collections/2573733-business-intelligence-bi",
  crm: SUPPORT_PAGE_BASE_URL + "crm",
  dashboard: SUPPORT_PAGE_BASE_URL,
  "environment-management": SUPPORT_PAGE_BASE_URL,
  hours: SUPPORT_PAGE_BASE_URL + "uren",
  hrm: SUPPORT_PAGE_BASE_URL + "hrm",
  insights: SUPPORT_PAGE_BASE_URL, // TODO: append suffix once customer support has created the page
  invoicing: SUPPORT_PAGE_BASE_URL + "facturen",
  projects: SUPPORT_PAGE_BASE_URL + "projecten",
  "resource-planner": SUPPORT_PAGE_BASE_URL,
  sales: SUPPORT_PAGE_BASE_URL + "sales",
};

type HelpDropdownProps = {
  module?: string;
};

export const HelpDropdown = ({ module }: HelpDropdownProps) => {
  const { t } = useTranslation("top_bar");
  const { show } = useIntercom();
  const { data: intercomSettings } = useGetIntercomSettingsQuery(undefined, { refetchOnMountOrArgChange: true });
  const selectedModule = asModule(module) ?? "dashboard";

  const translatedModuleThemes: Record<Module, string> = {
    "business-intelligence": t("modules.business_intelligence", { ns: "general" }),
    crm: t("modules.crm", { ns: "general" }),
    dashboard: t("modules.dashboard", { ns: "general" }),
    "environment-management": t("modules.settings", { ns: "general" }),
    hours: t("modules.hours", { ns: "general" }),
    hrm: t("modules.hrm", { ns: "general" }),
    insights: t("modules.insights", { ns: "general" }),
    invoicing: t("modules.invoicing", { ns: "general" }),
    projects: t("modules.projects", { ns: "general" }),
    "resource-planner": t("modules.resource_planner", { ns: "general" }),
    sales: t("modules.sales", { ns: "general" }),
  };

  return (
    <div>
      <div className={classnames(styles.container, styles.divider)}>
        <div className={styles.innerContainer}>
          <p className={styles.text}>{t("help_text_1", { module: translatedModuleThemes[selectedModule] })}</p>
          <Button variant="primary" size="medium" target="_blank" to={SUPPORT_URLS[selectedModule]}>
            {t("help_button_support")}
          </Button>
        </div>
        <div className={styles.innerContainer}>
          <p className={styles.text}>{t("help_text_2")}</p>
          <Button
            variant="secondary"
            size="medium"
            disabled={!intercomSettings?.is_enabled}
            onClick={show}
            testId="help-question-button"
          >
            {t("help_button_question")}
          </Button>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <p className={styles.text}>{t("help_text_3")}</p>
          <Button variant="primary" size="medium" target="_blank" to={PRODUCT_PORTAL_URL}>
            {t("help_button_portal")}
          </Button>
        </div>
      </div>
    </div>
  );
};
