import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";

export type DefaultService = {
  id: string;
  description: string;
  invoiceMethod: "fixed_price" | "subscription" | "subsequent_calculation";
};

const tags = createTagTypes({
  tagPrefix: "default_service",
  tags: ["list"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getDefaultServices: builder.query<DefaultService[], void>({
      query: () => ({
        url: "/defaultService.list",
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetDefaultServicesQuery } = endpoints;
