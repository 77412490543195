import { skipToken } from "@reduxjs/toolkit/query/react";
import {
  InvoiceMethod,
  Big,
  useBuildProjectServiceQuery,
  useCreateProjectServiceMutation,
  transformMoneyFromAPIToMoney,
  useFeatureFlag,
  type BuiltProjectService,
  useEditProjectServiceMutation,
} from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { showToast, type DialogHandle } from "@simplicate/ui";
import { useFormik, FormikErrors, type FormikTouched } from "formik";
import { type RefObject, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setMargin, setPurchasePrice, setQuantity, setSellingPrice } from "./costTypeInFormUtils";
import { buildValidationSchema } from "./projectServiceFormValidation";
import { transformCostTypeInServiceToCostTypeInForm } from "./transformCostTypes";
import {
  transformFormToCreateProjectServiceBody,
  transformFormToEditProjectServiceBody,
} from "./transformFormToCreateProjectServiceBody";
import { transformToHourTypeInForm } from "./transformHourTypes";
import type { DefaultServiceDialogForm } from "./ApplyChangedDefaultServiceValuesDialog";
import type { ProjectServiceForm, ValidProjectServiceForm, HourTypeInForm, CostTypeInForm } from "./types";

interface ProjectServiceFormErrors extends FormikErrors<ProjectServiceForm> {
  costsOrHours?: string;
}

type UseProjectServiceFormProps = {
  initialValues?: ProjectServiceForm;
  projectId?: string;
  dialogRef: RefObject<DialogHandle<DefaultServiceDialogForm>>;
  afterSubmitTarget?: string;
  serviceId?: string;
};

const INITIAL_VALUES: ProjectServiceForm = {
  defaultService: undefined,
  invoiceMethod: undefined,
  description: undefined,
  explanation: undefined,
  timeframe: {
    startDate: undefined,
    endDate: undefined,
  },
  revenueGroup: undefined,
  vatCode: undefined,
  invoiceableFrom: undefined,
  invoiceInInstallments: undefined,
  canRegisterHours: undefined,
  canRegisterCosts: undefined,
  isPlannable: undefined,
  hourTypes: [],
  costTypes: [],
  hasInstallmentPlan: false,
  hasAssignments: false,
};

export const useProjectServiceForm = ({
  initialValues = INITIAL_VALUES,
  projectId,
  dialogRef,
  afterSubmitTarget = "",
  serviceId,
}: UseProjectServiceFormProps) => {
  const validationSchema = buildValidationSchema();
  const hasResourcePlanner = useFeatureFlag("resource-planner").enabled;
  const isEditingService = serviceId !== undefined;

  const { t } = useTranslation("project_services");
  const navigate = useNavigate();

  /* istanbul ignore next -- RTK is mocked in tests */
  const [createProjectService, { isSuccess: isCreateSuccess, isError: isCreateError }] =
    useCreateProjectServiceMutation({
      selectFromResult: ({ isSuccess, isError }) => ({ isSuccess, isError }),
    });

  const [editProjectService, { isSuccess: isEditSuccess, isError: isEditError }] = useEditProjectServiceMutation({
    selectFromResult: ({ isSuccess, isError }) => ({ isSuccess, isError }),
  });

  const isError = isCreateError || isEditError;
  const isSuccess = isCreateSuccess || isEditSuccess;

  useEffect(() => {
    if (!isError) return;

    showToast({ message: t("error_save"), type: "error" });
  }, [isError, t]);

  useEffect(() => {
    if (!isSuccess) return;

    navigate(afterSubmitTarget);
  }, [isSuccess, navigate, afterSubmitTarget]);

  const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, setErrors, isSubmitting } =
    useFormik<ProjectServiceForm>({
      initialValues: {
        ...initialValues,
        timeframe: initialValues.timeframe,
        isPlannable: initialValues.isPlannable ?? true,
      },
      onSubmit: (values) => {
        if (!projectId) {
          return;
        }

        if (isEditingService) {
          return editProjectService(
            transformFormToEditProjectServiceBody(values as ValidProjectServiceForm, serviceId, hasResourcePlanner),
          );
        }

        return createProjectService(
          transformFormToCreateProjectServiceBody(values as ValidProjectServiceForm, projectId, hasResourcePlanner),
        );
      },
      validationSchema,
      enableReinitialize: true,
    });

  const setFieldValueAndHandleErrors = useCallback(
    async (fieldName: string, fieldValue: unknown) => {
      const errors = await setFieldValue(fieldName, fieldValue);

      if (errors) {
        setErrors(errors);
      }
    },
    [setErrors, setFieldValue],
  );

  const setDefaultService = useCallback(
    (defaultService: string) => {
      if (!serviceId && values.defaultService !== undefined && isAnyRelevantFieldTouched(touched)) {
        void dialogRef.current?.open().then(async ({ data, status }) => {
          if (status === "cancel") {
            return;
          }

          if (data?.defaultServiceChanged === "discard-touched") {
            await resetTouchedOnRelevantFields(setFieldTouched);
          }

          void setFieldTouched("defaultService");
          void setFieldValueAndHandleErrors("defaultService", defaultService);
        });

        return;
      }
      void setFieldTouched("defaultService");
      void setFieldValueAndHandleErrors("defaultService", defaultService);
    },
    [setFieldTouched, values.defaultService, touched, setFieldValueAndHandleErrors, dialogRef, serviceId],
  );

  const setInvoiceMethod = useCallback(
    (invoiceMethod: InvoiceMethod) => {
      if (invoiceMethod === InvoiceMethod.subscription) return;
      void setFieldTouched("invoiceMethod");
      void setFieldValueAndHandleErrors("invoiceMethod", invoiceMethod);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setDescription = useCallback(
    (description: string) => {
      void setFieldTouched("description");
      void setFieldValueAndHandleErrors("description", description);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setExplanation = useCallback(
    (explanation: string) => {
      void setFieldTouched("explanation");
      void setFieldValueAndHandleErrors("explanation", explanation);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setInvoiceableFrom = useCallback(
    (invoiceableFrom: Date | undefined) => {
      void setFieldTouched("invoiceableFrom");
      void setFieldValueAndHandleErrors("invoiceableFrom", invoiceableFrom);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setInvoiceInInstallments = useCallback(
    (invoiceInInstallments: boolean) => {
      void setFieldTouched("invoiceInInstallments");
      void setFieldValueAndHandleErrors("invoiceInInstallments", invoiceInInstallments);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setStartDate = useCallback(
    (startDate: Date | undefined) => {
      void setFieldTouched("timeframe.startDate");
      void setFieldValueAndHandleErrors("timeframe.startDate", startDate);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setEndDate = useCallback(
    (endDate: Date | undefined) => {
      void setFieldTouched("timeframe.endDate");
      void setFieldValueAndHandleErrors("timeframe.endDate", endDate);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setRevenueGroup = useCallback(
    (revenueGroup: string) => {
      void setFieldTouched("revenueGroup");
      void setFieldValueAndHandleErrors("revenueGroup", revenueGroup);
    },
    [setFieldValueAndHandleErrors, setFieldTouched],
  );

  const setVatCode = useCallback(
    (VATCode: string) => {
      void setFieldTouched("vatCode");
      void setFieldValueAndHandleErrors("vatCode", VATCode);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setCanRegisterHours = useCallback(
    (newValue: boolean) => {
      void setFieldTouched("canRegisterHours");
      void setFieldValueAndHandleErrors("canRegisterHours", newValue);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setCanRegisterCosts = useCallback(
    (newValue: boolean) => {
      void setFieldTouched("canRegisterCosts");
      void setFieldValueAndHandleErrors("canRegisterCosts", newValue);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setHourTypesTotal = useCallback(
    (value: Big | undefined) => {
      void setFieldTouched("hourTypesSpecifiedTotal");
      void setFieldValueAndHandleErrors("hourTypesSpecifiedTotal", {
        ...values.hourTypesSpecifiedTotal,
        amount: value,
      });
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypesSpecifiedTotal],
  );

  const addNewHourTypeEntry = useCallback(() => {
    void setFieldValueAndHandleErrors("hourTypes", [
      ...(values.hourTypes ?? []),
      {
        id: "",
        name: "",
        amount: 0,
        hourlyRate: { amount: Big(0), currency: "EUR" },
        total: { amount: Big(0), currency: "EUR" },
        isNewEntry: true,
        hasRegistrations: false,
      } satisfies HourTypeInForm,
    ]);
  }, [setFieldValueAndHandleErrors, values.hourTypes]);

  const removeNewHourTypeEntry = useCallback(() => {
    void setFieldValueAndHandleErrors("hourTypes", [
      ...(values.hourTypes ?? []).filter((hourType) => !hourType.isNewEntry),
    ]);
  }, [setFieldValueAndHandleErrors, values.hourTypes]);

  const addHourType = useCallback(
    (hourType: HourTypeInForm) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors("hourTypes", [
        ...(values.hourTypes ?? []).filter((hourType) => !hourType.isNewEntry),
        hourType,
      ]);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const removeHourTypes = useCallback(
    (ids: string[]) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.filter((hourType) => !ids.includes(hourType.id)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const setAmountForHourType = useCallback(
    (hourTypeId: string, amount: number | undefined) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.id === hourTypeId
            ? {
                ...hourType,
                amount,
                total: { ...hourType.total, amount: hourType.hourlyRate?.amount?.mul(amount ?? 0) ?? Big(0) },
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const setHourlyRateForHourType = useCallback(
    (hourTypeId: string, hourlyRateAmount: Big | undefined) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.id === hourTypeId
            ? {
                ...hourType,
                hourlyRate: hourlyRateAmount ? { ...hourType.hourlyRate, amount: hourlyRateAmount } : undefined,
                total: { ...hourType.total, amount: hourlyRateAmount?.mul(hourType.amount ?? 0) ?? Big(0) },
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const toggleIsInvoiceableForHourType = useCallback(
    (hourTypeId: string) => {
      void setFieldTouched("hourTypes");
      void setFieldValueAndHandleErrors(
        "hourTypes",
        values.hourTypes?.map((hourType) =>
          hourType.id === hourTypeId
            ? {
                ...hourType,
                isInvoiceable: !hourType.isInvoiceable,
              }
            : hourType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.hourTypes],
  );

  const toggleIsInvoiceableForCostType = useCallback(
    (costTypeId: string) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) =>
          costType.id === costTypeId
            ? {
                ...costType,
                isInvoiceable: !costType.isInvoiceable,
              }
            : costType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const addCostType = useCallback(
    (costType: CostTypeInForm) => {
      void setFieldTouched("costTypes");
      // istanbul ignore next -- In real world scenario, this will never be undefined
      void setFieldValueAndHandleErrors("costTypes", [...(values.costTypes ?? []), costType]);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const removeCostType = useCallback(
    (id: string) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors("costTypes", values.costTypes?.filter((costType) => costType.id !== id) ?? []);
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setLabelForCostType = useCallback(
    (costTypeId: string, label: string) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) => (costType.id === costTypeId ? { ...costType, name: label } : costType)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setQuantityForCostType = useCallback(
    (costTypeId: string, quantity: number | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) => (costType.id === costTypeId ? setQuantity(costType, quantity) : costType)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setPurchasePriceForCostType = useCallback(
    (costTypeId: string, purchasePriceAmount: Big | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) =>
          costType.id === costTypeId ? setPurchasePrice(costType, purchasePriceAmount) : costType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setMarginForCostType = useCallback(
    (costTypeId: string, margin: number | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) => (costType.id === costTypeId ? setMargin(costType, margin) : costType)),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setSellingPriceForCostType = useCallback(
    (costTypeId: string, sellingPriceAmount: Big | undefined) => {
      void setFieldTouched("costTypes");
      void setFieldValueAndHandleErrors(
        "costTypes",
        values.costTypes?.map((costType) =>
          costType.id === costTypeId ? setSellingPrice(costType, sellingPriceAmount) : costType,
        ),
      );
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.costTypes],
  );

  const setIsPlannable = useCallback(
    (isPlannable: boolean) => {
      void setFieldTouched("isPlannable");
      void setFieldValueAndHandleErrors("isPlannable", isPlannable);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setInvoicePrice = useCallback(
    (amount: Big | undefined) => {
      void setFieldTouched("invoicePrice");
      void setFieldValueAndHandleErrors("invoicePrice", { ...values.invoicePrice, amount });
    },
    [setFieldTouched, setFieldValueAndHandleErrors, values.invoicePrice],
  );

  const setValuesFromProjectService = useCallback(
    // eslint-disable-next-line complexity, sonarjs/cognitive-complexity -- We want to set all values here if not touched, separating complexity will only make it less readable
    (buildProjectService: BuiltProjectService) => {
      if (!touched.invoiceMethod) {
        const method =
          buildProjectService.invoiceMethod !== InvoiceMethod.subscription
            ? buildProjectService.invoiceMethod
            : undefined;

        void setFieldValueAndHandleErrors("invoiceMethod", method);
      }
      if (!touched.description) {
        void setFieldValueAndHandleErrors("description", buildProjectService.description);
      }
      if (!touched.revenueGroup) {
        void setFieldValueAndHandleErrors("revenueGroup", buildProjectService.revenueGroup?.id);
      }
      if (!touched.vatCode) {
        void setFieldValueAndHandleErrors("vatCode", buildProjectService.vatCode?.id);
      }
      if (!touched.canRegisterHours) {
        void setFieldValueAndHandleErrors("canRegisterHours", buildProjectService.canRegisterHours);
      }
      if (!touched.canRegisterCosts) {
        void setFieldValueAndHandleErrors("canRegisterCosts", buildProjectService.canRegisterCosts);
      }
      if (!touched.isPlannable) {
        void setFieldValueAndHandleErrors("isPlannable", buildProjectService.isPlannable ?? false);
      }
      if (!touched.hourTypes && !touched.hourTypesSpecifiedTotal) {
        void setFieldValueAndHandleErrors(
          "hourTypes",

          buildProjectService.hourTypeConfiguration.hourTypes.map((hourTypeInService) =>
            transformToHourTypeInForm(hourTypeInService),
          ),
        );
        void setFieldValueAndHandleErrors(
          "hourTypesSpecifiedTotal",
          transformMoneyFromAPIToMoney(buildProjectService.hourTypeConfiguration.hourTypeTotals.specifiedTotal),
        );
      }
      if (!touched.costTypes) {
        void setFieldValueAndHandleErrors(
          "costTypes",
          buildProjectService.costTypes.map((costTypeInService) =>
            transformCostTypeInServiceToCostTypeInForm(costTypeInService),
          ),
        );
      }
      if (!touched.invoicePrice) {
        void setFieldValueAndHandleErrors(
          "invoicePrice",
          transformMoneyFromAPIToMoney(buildProjectService.invoicePrice ?? { amount: "0", currency: "EUR" }),
        );
      }
    },
    [
      touched.invoiceMethod,
      touched.description,
      touched.revenueGroup,
      touched.vatCode,
      touched.canRegisterHours,
      touched.canRegisterCosts,
      touched.isPlannable,
      touched.hourTypes,
      touched.hourTypesSpecifiedTotal,
      touched.costTypes,
      touched.invoicePrice,
      setFieldValueAndHandleErrors,
    ],
  );

  useBuildProjectService({
    defaultServiceValue: values.defaultService,
    projectId,
    isEditingService,
    setValuesFromProjectService,
  });

  return {
    values,
    errors: errors as ProjectServiceFormErrors,
    touched,
    setDefaultService,
    setInvoiceMethod,
    setDescription,
    setExplanation,
    setInvoiceableFrom,
    setInvoiceInInstallments,
    setStartDate,
    setEndDate,
    setRevenueGroup,
    setVatCode,
    setCanRegisterHours,
    setCanRegisterCosts,
    setAmountForHourType,
    setHourlyRateForHourType,
    toggleIsInvoiceableForHourType,
    toggleIsInvoiceableForCostType,
    setIsPlannable,
    setHourTypesTotal,
    addNewHourTypeEntry,
    removeNewHourTypeEntry,
    addHourType,
    removeHourTypes,
    addCostType,
    removeCostType,
    setLabelForCostType,
    setQuantityForCostType,
    setPurchasePriceForCostType,
    setMarginForCostType,
    setSellingPriceForCostType,
    setInvoicePrice,
    handleSubmit,
    isSubmitting,
  };
};

type useBuildProjectServiceProps = {
  projectId: string | undefined;
  isEditingService: boolean;
  defaultServiceValue: string | undefined;
  setValuesFromProjectService: (projectService: BuiltProjectService) => void;
};

const useBuildProjectService = ({
  defaultServiceValue,
  projectId,
  isEditingService,
  setValuesFromProjectService,
}: useBuildProjectServiceProps) => {
  const {
    data: projectService,
    isSuccess,
    isFetching,
  } = useBuildProjectServiceQuery(
    defaultServiceValue && projectId && !isEditingService
      ? { defaultServiceId: defaultServiceValue, projectId: projectId }
      : skipToken,
  );

  useEffect(() => {
    if (isFetching || !isSuccess || projectService === undefined) {
      return;
    }

    setValuesFromProjectService(projectService);
  }, [isSuccess, projectService, setValuesFromProjectService, isFetching]);
};

// eslint-disable-next-line complexity -- a lot of fields can be touched
function isAnyRelevantFieldTouched(touched: FormikTouched<ProjectServiceForm>) {
  return (
    (touched.invoiceMethod ||
      touched.explanation ||
      touched.revenueGroup ||
      touched.vatCode ||
      touched.canRegisterHours ||
      touched.canRegisterCosts ||
      touched.hourTypes ||
      touched.costTypes ||
      touched.hourTypesSpecifiedTotal ||
      touched.invoicePrice ||
      touched.description) ??
    false
  );
}

async function resetTouchedOnRelevantFields(
  setFieldTouched: (field: string, touched: boolean) => Promise<FormikErrors<ProjectServiceForm>> | Promise<void>,
) {
  await Promise.all([
    setFieldTouched("invoiceMethod", false),
    setFieldTouched("description", false),
    setFieldTouched("explanation", false),
    setFieldTouched("revenueGroup", false),
    setFieldTouched("vatCode", false),
    setFieldTouched("canRegisterHours", false),
    setFieldTouched("canRegisterCosts", false),
    setFieldTouched("hourTypes", false),
    setFieldTouched("costTypes", false),
    setFieldTouched("hourTypesSpecifiedTotal", false),
    setFieldTouched("invoicePrice", false),
  ]);
}
