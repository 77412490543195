import { Chart as ChartJS, ChartData, BarElement, CategoryScale, LinearScale, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import { initialize } from "../../utils/initialize";
import styles from "./Bar.chart.module.scss";

initialize();

ChartJS.register(CategoryScale, LinearScale, BarElement);

const DEFAULT_OPTIONS: ChartOptions<"bar"> = {
  plugins: {
    legend: {
      align: "start",
      position: "bottom",
    },
  },
  datasets: {
    bar: {
      maxBarThickness: 32,
      barPercentage: 0.9,
      categoryPercentage: 0.9,
    },
  },
};

type BarChartProps<TData extends number[], TLabel> = {
  data: ChartData<"bar", TData, TLabel>;
};

export const BarChart = <TData extends number[], TLabel>({ data }: BarChartProps<TData, TLabel>) => {
  return <Bar className={styles.chart} data={data} options={DEFAULT_OPTIONS} />;
};
