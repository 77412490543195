import { useFormik, type FormikErrors } from "formik";
import { useCallback } from "react";
import { buildValidationSchema } from "./CostTypeMutationFormValidation";

export type CostTypeMutationForm = {
  label?: string;
  costTypeId?: string;
};

type useCostTypeMutationFormProps = {
  onSubmit: (label: string | undefined) => void;
  initialValues?: CostTypeMutationForm;
};

export const useCostTypeMutationForm = ({ onSubmit, initialValues }: useCostTypeMutationFormProps) => {
  const validationSchema = buildValidationSchema();

  const { values, errors, touched, setFieldValue, setErrors, setFieldTouched, isValid, submitForm } =
    useFormik<CostTypeMutationForm>({
      initialValues: initialValues ?? {
        label: undefined,
        costTypeId: undefined,
      },
      onSubmit: /* istanbul ignore next - onSubmit function is mocked in tests */ (form) => {
        onSubmit(form.label);
      },
      validationSchema,
    });

  const setFieldValueAndHandleErrors = useCallback(
    async (fieldName: string, fieldValue: unknown) => {
      // validateOnChange is set to true by default, so resolve type of promise is never void
      const errors = (await setFieldValue(fieldName, fieldValue)) as FormikErrors<CostTypeMutationForm>;

      setErrors(errors);
    },
    [setErrors, setFieldValue],
  );

  const setCostTypeId = useCallback(
    async (costTypeId: string) => {
      await setFieldTouched("costTypeId");
      await setFieldValueAndHandleErrors("costTypeId", costTypeId);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  const setLabel = useCallback(
    async (label: string | undefined, touch: boolean = true) => {
      if (touch) {
        await setFieldTouched("label");
      }
      await setFieldValueAndHandleErrors("label", label);
    },
    [setFieldTouched, setFieldValueAndHandleErrors],
  );

  return {
    values,
    errors,
    touched,
    setCostTypeId,
    setLabel,
    isValid,
    submitForm,
  };
};
