import { useTranslation } from "@simplicate/translations";
import { Button, Clickable, Dialog, Icon, RadioButton } from "@simplicate/ui";
import { forwardRef, useState } from "react";
import styles from "./ApplyChangedDefaultServiceValueDialog.module.scss";

export type DefaultServiceDialogForm = {
  defaultServiceChanged: "discard-touched" | "keep-touched";
};

export const ApplyChangedDefaultServiceValuesDialog = forwardRef(function DefaultServicesValuesDialog(_, ref) {
  const { t } = useTranslation("project_services");

  const [selectedMethod, setSelectedMethod] = useState<"discard-touched" | "keep-touched">("keep-touched");

  return (
    <Dialog ref={ref}>
      <form onSubmit={() => setSelectedMethod("keep-touched")}>
        <div className={styles.container}>
          <Clickable type="reset" className={styles.closeButton}>
            <Icon icon="times" />
          </Clickable>
          <h3 className={styles.title}>{t("change_default_service_title")}</h3>
          <span className={styles.text}>{t("change_default_service_info_text")}</span>
          <RadioButton
            value={selectedMethod}
            options={[
              { label: t("keep_touched_values_when_changing_default_service"), value: "keep-touched" },
              { label: t("discard_touched_values_when_changing_default_service"), value: "discard-touched" },
            ]}
            name="defaultServiceChanged"
            onChange={(value) => setSelectedMethod(value)}
          />
          <div className={styles.buttonContainer}>
            <Button testId="confirm-changing-default-service" type="submit">
              {t("confirm_changing_default_service")}
            </Button>
            <Button variant="subtle" type="reset">
              {t("cancel_changing_default_service")}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
});
