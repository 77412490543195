import { useTranslation } from "@simplicate/translations";
import { Button } from "@simplicate/ui";

type FooterProps = {
  deleteServices: () => void;
  cancel: () => void;
};
export const Footer = ({ deleteServices, cancel }: FooterProps) => {
  const { t } = useTranslation("grouped_services_manager");

  return (
    <div>
      <Button variant="danger" onClick={deleteServices}>
        {t("delete")}
      </Button>
      <Button variant="subtle" onClick={cancel}>
        {t("cancel")}
      </Button>
    </div>
  );
};
