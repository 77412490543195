// istanbul ignore file -- This page is primarily for internal use
import { Spinner } from "@simplicate/ui";
import { Suspense, lazy } from "react";

const Component = lazy(() => import("./VersionPage"));

export const VersionPage = () => {
  return (
    <Suspense fallback={<Spinner size="large" />}>
      <Component />
    </Suspense>
  );
};
