import { GroupedServicesManager } from "@simplicate/grouped-services-manager";
import { useTranslation } from "@simplicate/translations";
import { Page, Button, Dropdown, ListItem, List, PageHeader, Icon } from "@simplicate/ui";
import { lightColorIconInvert } from "@simplicate-software/design-tokens";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AddServiceGroupModal } from "./components/AddServiceGroupModal";
import { useSalesServicesPageData } from "./SalesServicesPage.hooks";
import styles from "./SalesServicesPage.module.scss";

export const SalesServicesPage = () => {
  const { id: saleId } = useParams<{ id: string }>();
  const { treeData, setAndSaveTreeData, rootId, groupServicesByInvoiceMethod } = useSalesServicesPageData(saleId);
  const { t } = useTranslation("sales_services");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Page>
      <PageHeader title={t("services_page_title")}>
        <div className={styles.buttonDropdownWrapper}>
          <Button
            variant="primary"
            size="medium"
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
          >
            {t("add_services_dropdown")}
            <Icon icon="chevronDown" color={lightColorIconInvert} />
          </Button>

          {dropdownOpen && (
            <div className={styles.dropdownContainer}>
              <Dropdown
                isOpen={dropdownOpen}
                width="auto"
                variant="clean"
                onClickOutside={/* istanbul ignore next */ () => setDropdownOpen(false)}
              >
                <List>
                  <ListItem>
                    <Button variant="invisible" to={`/sales/${saleId}/services/new`}>
                      {t("add_service_button")}
                    </Button>
                  </ListItem>
                  <ListItem>
                    <Button
                      variant="invisible"
                      onClick={() => {
                        setModalKey((prev) => prev + 1);
                        setModalOpen(true);
                        setDropdownOpen(false);
                      }}
                    >
                      {t("add_services_group_button")}
                    </Button>
                  </ListItem>
                </List>
              </Dropdown>
            </div>
          )}
        </div>
      </PageHeader>

      {treeData && (
        <GroupedServicesManager
          treeData={treeData}
          onChangeTree={setAndSaveTreeData}
          rootId={rootId}
          onAutoGroup={groupServicesByInvoiceMethod}
        />
      )}
      {saleId && (
        <AddServiceGroupModal saleId={saleId} isOpen={modalOpen} onClose={() => setModalOpen(false)} key={modalKey} />
      )}
    </Page>
  );
};
