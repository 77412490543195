import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Button, Icon } from "@simplicate/ui";
import { lightColorIconError } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteGroupModal } from "../DeleteGroupModal";
import styles from "./ServiceGroupHeader.module.scss";

type ServiceGroupHeaderProps = {
  text: string;
  isUngroupedContainer: boolean;
  onDuplicateGroup?: () => void;
  onAutoGroup?: () => void;
  deleteCallback?: (shouldDeleteServices: boolean) => void;
  serviceGroupId?: string;
};

export const ServiceGroupHeader = ({
  text,
  isUngroupedContainer,
  onDuplicateGroup,
  onAutoGroup,
  deleteCallback,
  serviceGroupId,
}: ServiceGroupHeaderProps) => {
  const { t } = useTranslation("grouped_services_manager");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id: saleId } = useParams<{ id: string }>();

  const handleDeleteGroup = (shouldDeleteServices: boolean) => {
    deleteCallback?.(shouldDeleteServices);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.header} data-testid="service-group-header">
        <div className={styles.headerLeft}>
          <span className={classNames(styles.grip, isUngroupedContainer && styles.hidden)}>
            <Icon icon="grip" />
          </span>
          <h2 className={styles.groupName}>{text}</h2>
        </div>
        <div className={styles.headerRight}>
          {isUngroupedContainer ? (
            onAutoGroup && (
              <Button variant="secondary" onClick={onAutoGroup}>
                {t("group_by_invoice_method")}
                <Icon icon="wand" />
              </Button>
            )
          ) : (
            <div className={styles.headerRight}>
              <ActionDropdown testId="dropdown-menu" ariaLabel={t("actions")}>
                <ActionDropdown.Action to={`/sales/${saleId}/services/new?serviceGroupId=${serviceGroupId}`}>
                  <Icon icon="plus" />
                  {t("add_service")}
                </ActionDropdown.Action>
                <ActionDropdown.Action onClick={onDuplicateGroup}>
                  <Icon icon="copy" />
                  {t("duplicate")}
                </ActionDropdown.Action>
                <ActionDropdown.Action
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  className={styles.deleteButton}
                >
                  <Icon icon="trash" color={lightColorIconError} />
                  {t("delete")}
                </ActionDropdown.Action>
              </ActionDropdown>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className={styles.deleteModal}>
          <DeleteGroupModal
            isOpen={isModalOpen}
            deleteServiceGroup={handleDeleteGroup}
            onClose={() => {
              setIsModalOpen(false);
            }}
          />
        </div>
      )}
    </>
  );
};
