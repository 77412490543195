import classNames from "classnames";
import { Calendar, CalendarPropsSingle } from "primereact/calendar";
import { useRef } from "react";
import { Icon } from "../Icon";
import styles from "./Datepicker.module.scss";

type DatepickerProps = Omit<
  CalendarPropsSingle,
  "className" | "dateFormat" | "dateTemplate" | "inputClassName" | "inputRef" | "onChange" | "pt" | "view"
> & {
  testId?: string;
  label?: string;
  invalid?: boolean;
  onChange?: (value: Date | undefined) => void;
};

const READABLE_PRIMEREACT_DATE_FORMAT = "d MM yy" as const;

// TODO: improve test coverage
/* istanbul ignore next -- this component is insufficiently covered */
export const Datepicker = ({
  testId,
  name,
  label,
  disabled,
  invalid = false,
  value,
  onChange,
  ...props
}: DatepickerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const defaultProps: CalendarPropsSingle = {
    view: "date",
    dateFormat: READABLE_PRIMEREACT_DATE_FORMAT,
    showWeek: true,
    readOnlyInput: true,
    dateTemplate: ({ day }) => <span className={classNames(styles.day)}>{day}</span>,
    pt: {
      input: {
        root: () => {
          if (!testId) {
            return {};
          }

          return { "data-testid": `${testId}-input` };
        },
      },
      title: () => ({
        style: { order: 1, marginLeft: 0 },
      }),
      monthTitle: () => ({
        style: { marginRight: 0 },
      }),
      previousButton: () => ({
        style: { order: 2 },
      }),
      nextButton: () => ({
        style: { order: 3 },
      }),
      weekHeader: () => ({
        className: styles.weekHeader,
        children: null,
      }),
      weekNumber: () => ({
        className: styles.weekNumber,
      }),
      weekLabelContainer: () => ({
        className: styles.weekLabelContainer,
      }),
      day: () => ({
        className: styles.day,
      }),
      dayLabel: () => ({
        className: styles.dayLabel,
      }),
      buttonbar: () => ({
        className: styles.buttonbar,
      }),
    },
  };

  return (
    <div
      data-testid={testId && `${testId}-outer-wrapper`}
      className={classNames(styles.wrapper, disabled && styles.disabled)}
    >
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <div
        data-testid={testId && `${testId}-wrapper`}
        className={classNames(styles.inputWrapper, invalid && styles.invalid)}
      >
        <Calendar
          {...defaultProps}
          className={styles.dateWrapper}
          inputClassName={styles.input}
          inputRef={inputRef}
          data-testid={testId}
          name={name}
          disabled={disabled}
          value={value}
          onChange={(event) => onChange?.(event.value ?? undefined)}
          {...props}
        />
        <Icon icon="calendarAlt" testId={testId ? `${testId}-icon` : undefined} className={styles.icon} />
      </div>
    </div>
  );
};
