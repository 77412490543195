import { InvoiceMethod, useGetInvoiceMethodsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip } from "@simplicate/ui";
import { memo } from "react";

type InvoiceMethodSelectProps = {
  value: InvoiceMethod | undefined;
  touched?: boolean;
  error: string | undefined;
  onSelect: (method: InvoiceMethod) => void;
  disabled: boolean;
  disabledTooltip?: string;
};

export const InvoiceMethodSelect = memo(function InvoiceMethodSelect({
  value,
  error,
  onSelect,
  disabled,
  touched = false,
  disabledTooltip,
}: InvoiceMethodSelectProps) {
  const { t } = useTranslation("project_services");

  const labelsForInvoiceMethods = {
    [InvoiceMethod.fixed_price]: t("fixed_price"),
    [InvoiceMethod.subscription]: t("subscription"),
    [InvoiceMethod.time_and_expenses]: t("time_and_expenses"),
  };

  const { data: invoiceMethodsResponse } = useGetInvoiceMethodsQuery();

  const compatibleOptions = invoiceMethodsResponse?.invoiceMethods.map((option) => ({
    label: labelsForInvoiceMethods[option],
    value: option,
    disabled: option === InvoiceMethod.subscription,
    tooltip: option === InvoiceMethod.subscription ? t("subscription_disabled") : undefined,
  }));

  const select = (
    <Select
      testId="invoice_method_select"
      name="invoiceMethod"
      label={t("invoice_method")}
      placeholder={t("invoice_method_placeholder")}
      emptyMessage={t("no_default_services")}
      value={value}
      options={compatibleOptions}
      onSelect={onSelect}
      invalid={touched && error !== undefined}
      disabled={disabled}
      filter={false}
    />
  );

  return (
    <>
      {!disabledTooltip && select}
      {disabled && disabledTooltip && (
        <Tooltip message={disabledTooltip} position="right">
          {select}
        </Tooltip>
      )}
    </>
  );
});
