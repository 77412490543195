import { Export, ExportStatusCode, useDeleteExportMutation } from "@simplicate/api-client";
import { TFunction, useElapsedTimeMessage, useTranslation } from "@simplicate/translations";
import { Button, Icon, ListItem, Spinner } from "@simplicate/ui";
import { format as formatAsDate } from "date-fns";
import { memo, useState } from "react";
import styles from "./ExportItem.module.scss";

const FULL_DATE_FORMAT = "dd MMM yyyy HH:mm";

function translateModel(model: string, t: TFunction<"top_bar">): string {
  return (
    {
      absence: t("export_absence"),
      combined: t("export_combined"),
      employee: t("export_employee"),
      hours: t("export_hours"),
      invoice: t("export_invoice"),
      invoicing: t("export_invoicing"),
      leave: t("export_leave"),
      mrr: t("export_mrr"),
      opportunity: t("export_opportunity"),
      organization: t("export_organization"),
      person: t("export_person"),
      process: t("export_process"),
      project: t("export_project"),
      projectspurchase: t("export_projectspurchase"),
      projectspurchaselines: t("export_projectspurchaselines"),
      revenue: t("export_revenue"),
      sales: t("export_sales"),
    }[model.toLowerCase()] ?? model
  );
}

type ExportItemProps = Export & { onDownload: () => void };

export const ExportItem = memo(function ExportItemComponent({
  id,
  status,
  created_at,
  model,
  is_stuck,
  onDownload,
}: ExportItemProps) {
  const { t } = useTranslation("top_bar");
  const { format } = useElapsedTimeMessage();
  const [mutationIsPending, setMutationIsPending] = useState(false);
  const [deleteExport] = useDeleteExportMutation();

  const handleDeleteExport = () => {
    setMutationIsPending(true);
    void deleteExport(id);
  };

  const handleDownload = () => {
    setMutationIsPending(true);

    setTimeout(() => {
      onDownload();
    }, 250);
  };

  const formattedCreatedDate = format(new Date(created_at));
  const isLoading =
    status === ExportStatusCode.OPEN || (status === ExportStatusCode.IN_PROGRESS && !is_stuck) || mutationIsPending;
  const hasError = status === ExportStatusCode.FAILED || (status === ExportStatusCode.IN_PROGRESS && is_stuck);
  const canDelete = status !== ExportStatusCode.FAILED && !isLoading;
  const exportTitle = translateModel(model, t);

  return (
    <ListItem className={styles.exportItem}>
      <div className={styles.description}>
        {hasError && (
          <Icon
            icon="exclamationTriangle"
            className={styles.warningIcon}
            testId="export-warning-icon"
            title={t("export_failed")}
          />
        )}
        <span>{exportTitle}</span>
      </div>
      <span className={styles.dateLabel} title={formatAsDate(created_at, FULL_DATE_FORMAT)}>
        {formattedCreatedDate}
      </span>
      <div className={styles.actions}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Spinner size="small" testId="export-loading-spinner" />
          </div>
        ) : (
          <>
            <Button
              to={`${window.location.origin}/api/v2/download/export/${id}`}
              onClick={handleDownload}
              download={exportTitle}
              target="_blank"
              disabled={status === ExportStatusCode.FAILED || status === ExportStatusCode.IN_PROGRESS}
              variant="secondary"
              testId="download-button"
              hasInlinePadding={false}
            >
              <Icon icon="download" className={styles.icon} />
            </Button>
            <Button
              disabled={!canDelete}
              variant="secondary"
              onClick={handleDeleteExport}
              testId="delete-button"
              hasInlinePadding={false}
            >
              <Icon icon="trash" className={styles.icon} />
            </Button>
          </>
        )}
      </div>
    </ListItem>
  );
});
