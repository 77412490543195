import classNames from "classnames";
import { PropsWithChildren, ReactElement, useEffect, useRef } from "react";
import { PageHeader } from "../PageHeader";
import { usePortalContext } from "../PortalProvider";
import styles from "./Page.module.scss";

export const PAGE_STICKY_TOP = "page-top-bar";

type PageProps = PropsWithChildren & { footer?: ReactElement; title?: string; testId?: string };

export const Page = ({ children, footer, title, testId }: PageProps) => {
  const { setTargetRef } = usePortalContext();
  const stickyTopContainerRef = useRef(null);

  useEffect(() => {
    // istanbul ignore else -- this guard exists to satisfy TS, but it should always be set.
    if (stickyTopContainerRef.current) {
      setTargetRef(PAGE_STICKY_TOP, stickyTopContainerRef);
    }
  }, [setTargetRef]);

  return (
    <div className={classNames(styles.pageWrapper, footer && styles.hasFooter)} data-testid={testId}>
      <div ref={stickyTopContainerRef} className={styles.stickyPageTopContainer} />

      <section className={styles.page}>
        {title && <PageHeader title={title} />}
        {children}
      </section>

      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};
