import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";
import { InvoiceMethod, SubscriptionCycle } from "./InvoiceMethod";
import { MoneyFromAPI } from "./utils";
import type { CostType, HourTypeFromApi } from "../types";

export type HourTypeInCreateServiceBody = {
  defaultHourTypeId: string;
  hourlyRate: MoneyFromAPI;
  amountOfHours: number;
  isInvoiceable: boolean | undefined; // TODO: SIM-38114: Fix typo. This should be invoiceable
};

export type HourTypeInService = {
  defaultHourType: HourTypeFromApi;
  hourlyRate: MoneyFromAPI;
  amountOfHours: number;
  isInvoiceable: boolean | undefined;
  hasRegistrations: boolean;
};

export type CostTypeInCreateServiceBody = {
  id: string;
  defaultCostTypeId: string;
  description: string;
  purchasePrice: MoneyFromAPI;
  sellingPrice: MoneyFromAPI;
  quantity: number | undefined;
  isInvoiceable: boolean | undefined;
};

export type CostTypeInService = {
  id: string;
  defaultCostType: CostType;
  description?: string;
  purchasePrice: MoneyFromAPI;
  sellingPrice: MoneyFromAPI;
  quantity: number;
  isInvoiceable?: boolean;
  margin: number;
  total: MoneyFromAPI;
  hasRegistrations: boolean;
};

type BuildServiceBody = {
  defaultServiceId: string;
  projectId: string;
};

type BaseProjectServiceBody = {
  defaultServiceId: string;
  description: string;
  explanation: string | null;
  invoiceMethod: InvoiceMethod;
  timeFrame: {
    startDate: string | null;
    endDate: string | null;
  } | null;
  revenueGroupId: string;
  vatCodeId: string;
  hourTypeConfiguration: {
    hourTypes: HourTypeInCreateServiceBody[];
    employeeHourlyRates: object[];
    specifiedTotal: MoneyFromAPI;
  } | null;
  costTypes: CostTypeInCreateServiceBody[];
  isPlannable?: boolean;
  canRegisterHours: boolean;
  canRegisterCosts: boolean;
};

export type ProjectServiceBody = BaseProjectServiceBody | (BaseProjectServiceBody & FixedPriceServiceValues);

type FixedPriceServiceValues = {
  invoicePrice: MoneyFromAPI;
  invoiceableFrom: string | null;
  invoiceInInstallments: boolean;
};

export type CreateProjectServiceBody = ProjectServiceBody & {
  projectId: string;
};

export type EditProjectServiceBody = ProjectServiceBody & {
  id: string;
};

export type ProjectService = {
  projectId: string;
  invoiceableFrom?: string;
  timeFrame?: {
    startDate?: string;
    endDate?: string;
  };
  invoiceInInstallments: boolean;
  isPlannable?: boolean;
  id: string;
  defaultServiceId: string;
  description?: string;
  explanation?: string;
  invoicePrice?: MoneyFromAPI;
  invoiceMethod: InvoiceMethod;
  subscriptionCycle?: SubscriptionCycle;
  revenueGroupId: string;
  vatCodeId: string;
  hourTypeConfiguration: {
    hourTypes: HourTypeInService[];
    hourTypeTotals?: {
      calculatedTotal: MoneyFromAPI;
      specifiedTotal: MoneyFromAPI;
      hoursBudget: number;
    };
  };
  costTypes: CostTypeInService[];
  canRegisterHours: boolean;
  canRegisterCosts: boolean;
  costTypeTotals: {
    total: MoneyFromAPI;
    budget: MoneyFromAPI;
  };
  existsOnInvoice: boolean;
  hasInstallmentPlan: boolean;
  hasAssignments: boolean;
};

export type BuiltProjectService = {
  defaultServiceId: string;
  description?: string;
  invoicePrice?: MoneyFromAPI;
  invoiceMethod: InvoiceMethod;
  revenueGroup?: {
    id: string;
    name: string;
  };
  vatCode?: {
    id: string;
    name: string;
  };
  useEmployeeHourlyRate: boolean;
  hourTypeConfiguration: {
    hourTypes: HourTypeInService[];
    hourTypeTotals: {
      calculatedTotal: MoneyFromAPI;
      specifiedTotal: MoneyFromAPI;
      hoursBudget: number;
    };
  };
  costTypes: CostTypeInService[];
  isPlannable?: boolean;
  canRegisterHours: boolean;
  canRegisterCosts: boolean;
};

const tags = createTagTypes({
  tagPrefix: "ProjectService",
  tags: ["service"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    buildProjectService: builder.query<BuiltProjectService, BuildServiceBody>({
      query: (body) => ({
        url: "/projectService.build",
        method: "POST",
        body,
      }),
    }),
    createProjectService: builder.mutation<ProjectService, CreateProjectServiceBody>({
      query: (projectService) => ({
        url: "/projectService.create",
        method: "POST",
        body: projectService,
      }),
    }),
    getProjectService: builder.query<ProjectService, string>({
      query: (serviceId) => ({
        url: `/projectService.get/${serviceId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, serviceId) => [{ type: tags.service, id: serviceId }],
    }),
    editProjectService: builder.mutation<ProjectService, EditProjectServiceBody>({
      query: (body) => ({
        url: `/projectService.edit`,
        method: "POST",
        body,
      }),
      invalidatesTags: (_result, _error, body) => [{ type: tags.service, id: body.id }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useBuildProjectServiceQuery,
  useCreateProjectServiceMutation,
  useGetProjectServiceQuery,
  useEditProjectServiceMutation,
} = endpoints;
