import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTranslation } from "@simplicate/translations";
import { Button, Input, Select, Icon } from "@simplicate/ui";
import { lightColorIconSuccess } from "@simplicate-software/design-tokens";
import { useEffect, useRef } from "react";
import { useGetCostTypesQuery, useGetCostTypeQuery } from "../../../../data/endpoints";
import { transformCostTypeToCostTypeInForm } from "../../../../transformCostTypes";
import { type CostTypeInForm } from "../../../../types";
import { useCostTypeMutationForm } from "../../CostTypeMutationForm";
import styles from "./AddCostTypeRow.module.scss";

type CostTypeGridFooterProps = {
  onConfirmNewEntry: (entry: CostTypeInForm) => void;
  onCancelNewEntry: () => void;
  disabled?: boolean;
};

export const AddCostTypeRow = ({ onConfirmNewEntry, onCancelNewEntry, disabled = false }: CostTypeGridFooterProps) => {
  const { t } = useTranslation("project_services");
  const labelInputRef = useRef<HTMLInputElement>(null);

  /* istanbul ignore next - submitForm function is mocked in tests */
  const confirmEntry = (label: string | undefined) =>
    selectedCostType &&
    onConfirmNewEntry(transformCostTypeToCostTypeInForm(selectedCostType, label ?? selectedCostType.name));

  const { values, touched, errors, setCostTypeId, setLabel, isValid, submitForm } = useCostTypeMutationForm({
    onSubmit: confirmEntry,
  });

  const onCostTypeSelect = (value: string) => {
    void setCostTypeId(value);

    setTimeout(() => {
      labelInputRef.current?.focus();
      labelInputRef.current?.select();
    }, 100);
  };

  const onLabelKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
      void submitForm();

      return;
    }

    if (event.key === "Escape") {
      event.stopPropagation();
      event.preventDefault();
      onCancelNewEntry();
    }
  };

  const { data: options } = useGetCostTypesQuery();
  const { data: selectedCostType } = useGetCostTypeQuery(values.costTypeId ? values.costTypeId : skipToken);

  useEffect(() => {
    if (selectedCostType && (!touched.label || values.label === "")) {
      void setLabel(selectedCostType.name, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- This effect should NOT run when label value changes, only when costType changes
  }, [selectedCostType, setLabel, touched.label]);

  const compatibleOptions = options?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  return (
    <div className={styles.container} data-testid="add-new-cost-type-row">
      <div className={styles.input}>
        <Select
          testId="cost-type-select"
          value={values.costTypeId}
          invalid={touched.costTypeId && errors.costTypeId !== undefined}
          options={compatibleOptions}
          placeholder={t("cost_type_placeholder")}
          size="small"
          onSelect={onCostTypeSelect}
          disabled={disabled}
        />
      </div>
      <div className={styles.input}>
        <Input
          ref={labelInputRef}
          testId="cost-type-label"
          type="text"
          name="cost-type-label"
          value={values.label ?? ""}
          hasError={touched.label && errors.label !== undefined}
          onChange={(event) => void setLabel(event.target.value)}
          placeholder={t("cost_type_label_placeholder")}
          size="small"
          onKeyDown={onLabelKeyDown}
          disabled={disabled}
        />
      </div>
      <Button
        testId="confirm-new-cost-type"
        variant="secondary"
        onClick={() => void submitForm()}
        disabled={disabled || !isValid || (!touched.label && !touched.costTypeId)}
        type="button"
        hasInlinePadding={false}
      >
        <Icon icon="check" color={lightColorIconSuccess} />
      </Button>
      <Button
        testId="cancel-new-cost-type"
        variant="secondary"
        onClick={onCancelNewEntry}
        type="button"
        disabled={disabled}
        hasInlinePadding={false}
      >
        <Icon icon="times" />
      </Button>
    </div>
  );
};
