import { simplicateApiV3 } from "../components";
import { createTagTypes } from "../tags";

export type RevenueGroup = {
  id: string;
  name: string;
};

const tags = createTagTypes({
  tagPrefix: "RevenueGroup",
  tags: ["list"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getRevenueGroups: builder.query<RevenueGroup[], void>({
      query: () => ({
        url: "/revenueGroup.list",
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetRevenueGroupsQuery } = endpoints;
