import { Toggle, Tooltip } from "@simplicate/ui";

type CanRegisterHoursToggleProps = {
  label: React.ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled: boolean;
  tooltip?: string;
};

export const CanRegisterHoursToggle = ({ label, value, onChange, disabled, tooltip }: CanRegisterHoursToggleProps) => {
  const toggle = (
    <Toggle testId="can-register-hours-toggle" onChange={onChange} value={value} disabled={disabled} label={label} />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {toggle}
    </Tooltip>
  ) : (
    toggle
  );
};
