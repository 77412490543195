import { ProjectService, transformMoneyFromAPIToMoney } from "@simplicate/api-client";
import { transformCostTypeInServiceToCostTypeInForm } from "./transformCostTypes";
import { transformToHourTypeInForm } from "./transformHourTypes";
import { ProjectServiceForm } from "./types";

export const transformProjectServiceToForm = (projectService: ProjectService): ProjectServiceForm => {
  const {
    timeFrame: timeframe,
    hourTypeConfiguration: { hourTypeTotals },
  } = projectService;

  return {
    defaultService: projectService.defaultServiceId,
    invoiceMethod: projectService.invoiceMethod,
    description: projectService.description,
    explanation: projectService.explanation,
    timeframe: {
      startDate: timeframe?.startDate ? new Date(timeframe.startDate) : undefined,
      endDate: timeframe?.endDate ? new Date(timeframe.endDate) : undefined,
    },
    revenueGroup: projectService.revenueGroupId,
    vatCode: projectService.vatCodeId,
    invoiceableFrom: projectService.invoiceableFrom ? new Date(projectService.invoiceableFrom) : undefined,
    invoiceInInstallments: projectService.invoiceInInstallments,
    canRegisterHours: projectService.canRegisterHours,
    canRegisterCosts: projectService.canRegisterCosts,
    isPlannable: projectService.isPlannable,
    hourTypes: projectService.hourTypeConfiguration.hourTypes.map((hourType) => transformToHourTypeInForm(hourType)),
    hourTypesSpecifiedTotal: hourTypeTotals ? transformMoneyFromAPIToMoney(hourTypeTotals.specifiedTotal) : undefined,
    costTypes: projectService.costTypes.map((costType) => transformCostTypeInServiceToCostTypeInForm(costType)),
    invoicePrice: projectService.invoicePrice ? transformMoneyFromAPIToMoney(projectService.invoicePrice) : undefined,
    existsOnInvoice: projectService.existsOnInvoice,
    hasInstallmentPlan: projectService.hasInstallmentPlan,
    hasAssignments: projectService.hasAssignments,
  };
};
