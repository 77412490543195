/* istanbul ignore file -- IntersectionObserver does not exist in jest, nor does jsdom actually do any layouting. */
import { useState, useLayoutEffect } from "react";

export type IntersectionObserverOptions = {
  /**
   * Fraction of the target's visibility the observer's callback should be. 1 = entirely visible, 0 = entirely invisible
   */
  threshold?: number;

  /**
   * Additional margin to subtract from the observed element's bounding box before calculating visibility.
   * <br/>
   * Set this to a negative value to increase the observed bounding box, the observed element will be considered "out of view" sooner.
   * <br/>
   * Set this to a positive value to decrease the observed bounding box, the observed element will be considered "out of view" later.
   */
  margin?: string;
};

type IntersectionObserverResult = {
  inView: boolean;
};

export const useIntersectionObserver = (
  element: HTMLElement | null,
  options: IntersectionObserverOptions = {},
): IntersectionObserverResult => {
  const { threshold = 0, margin } = options;
  const [inView, setInView] = useState(false);

  useLayoutEffect(() => {
    if (!element) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setInView(entry.isIntersecting);
        });
      },
      { threshold, rootMargin: margin },
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element, margin, threshold]);

  return { inView };
};
