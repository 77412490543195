import { postIframeMessage, useAppDispatch, useAppSelector } from "@simplicate/state";
import { Button } from "@simplicate/ui";
import { useMemo } from "react";

export const IFrameActionButtons = () => {
  const { SELECT_SUB_NAV_BUTTONS } = useAppSelector(({ state }) => state.iframe.latestMessages);
  const dispatch = useAppDispatch();

  const buttonConfigs = useMemo(() => {
    const buttons = SELECT_SUB_NAV_BUTTONS?.payload.buttons ?? [];

    return [
      ...buttons.filter((button) => button.color !== "green"),
      ...buttons.filter((button) => button.color === "green"),
    ];
  }, [SELECT_SUB_NAV_BUTTONS?.payload.buttons]);

  const handleClick = (eventName: string) => {
    dispatch(postIframeMessage({ eventName }));
  };

  if (buttonConfigs.length === 0) {
    return null;
  }

  return (
    <>
      {buttonConfigs.map(({ title, color, eventName, disabled }) => {
        return (
          <Button
            key={eventName}
            variant={color === "green" ? "primary" : "secondary"}
            onClick={() => handleClick(eventName)}
            disabled={disabled}
          >
            {title}
          </Button>
        );
      })}
    </>
  );
};
