import { NodeModel } from "@minoru/react-dnd-treeview";
import { InvoiceMethod, SubscriptionCycle, MoneyFromAPI } from "@simplicate/api-client";
import { FixedInvoicePrice } from "@simplicate/api-client/src/types";

export type ServiceData = {
  invoiceMethod: InvoiceMethod;
  subscriptionCycle?: SubscriptionCycle;
  saleQuantity?: number;
  hoursTotalAmount?: number;
  fixedPrice?: FixedInvoicePrice;
  totalPrice?: MoneyFromAPI;
  hoursTotalBudget?: MoneyFromAPI;
  purchaseTotalBudget?: MoneyFromAPI;
  duplicateCallback?: () => void;
  deleteCallback?: () => void;
  editCallback?: () => void;
};

export enum ServiceGroupType {
  NORMAL = "normal",
  UNGROUPED = "ungrouped",
}

export type ServiceGroupData = {
  groupType?: ServiceGroupType;
  deleteCallback?: (shouldDeleteServices: boolean) => void;
  total?: MoneyFromAPI;
  autoGroupCallback?: () => void;
};

export type ServiceNodeModel = NodeModel<ServiceData>;
export type ServiceGroupNodeModel = NodeModel<ServiceGroupData>;
export type GroupedServicesNodeModel = NodeModel<ServiceData | ServiceGroupData>;
