import { InvoiceMethod } from "@simplicate/api-client";
import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import styles from "./ProjectServiceHeader.module.scss";

type ProjectServiceHeaderProps = {
  title: string;
  invoiceMethod?: InvoiceMethod;
  price?: Big;
  testId?: string;
};

export const ProjectServiceHeader = ({ invoiceMethod, title, price, testId }: ProjectServiceHeaderProps) => {
  const { t } = useTranslation("project_services");

  const labelsForInvoiceMethods = {
    [InvoiceMethod.fixed_price]: t("fixed_price"),
    [InvoiceMethod.subscription]: t("subscription"),
    [InvoiceMethod.time_and_expenses]: t("time_and_expenses"),
  };

  return (
    <header>
      <h2 className={styles.title} data-testid={testId && `${testId}-title`}>
        {title}
      </h2>

      {(invoiceMethod || price) && (
        <p className={styles.text}>
          {invoiceMethod && (
            <span data-testid={testId && `${testId}-invoice`}>{labelsForInvoiceMethods[invoiceMethod]}: </span>
          )}
          {price && <CurrencyFormat data-testid={testId && `${testId}-price`} value={price.toString()} />}
        </p>
      )}
    </header>
  );
};
