import { useTranslation } from "@simplicate/translations";
import { Datepicker, Tooltip } from "@simplicate/ui";
import { memo } from "react";

type InvoiceableFromDatepickerProps = {
  value: Date | undefined;
  error: string | undefined;
  touched?: boolean;
  onChange: (date: Date | undefined) => void;
  disabled?: boolean;
  tooltip?: string;
};

export const InvoiceableFromDatepicker = memo(function InvoiceableFromDatepicker({
  value,
  error,
  onChange,
  touched = false,
  disabled = false,
  tooltip,
}: InvoiceableFromDatepickerProps) {
  const { t } = useTranslation("project_services");

  const datepicker = (
    <Datepicker
      name="invoiceableFrom"
      testId="invoiceable-from-datepicker"
      label={t("invoiceable_from")}
      value={value}
      onChange={onChange}
      invalid={touched && error !== undefined}
      disabled={disabled}
      showButtonBar
    />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="right">
      {datepicker}
    </Tooltip>
  ) : (
    datepicker
  );
});
