import {
  simplicateApiV3,
  createTagTypes,
  transformMoneyFromAPIToMoney,
  type Money,
  type HourTypeFromApi,
} from "@simplicate/api-client";

export type HourType = Omit<HourTypeFromApi, "hourlyRate"> & {
  hourlyRate: Money;
};

const tags = createTagTypes({
  tagPrefix: "HourType",
  tags: ["list", "entry"],
});

const endpoints = simplicateApiV3.enhanceEndpoints({ addTagTypes: Object.values(tags) }).injectEndpoints({
  endpoints: (builder) => ({
    getHourType: builder.query<HourType, string>({
      query: (id) => ({
        url: `/hourType.get/${id}`,
      }),
      transformResponse: (response: HourTypeFromApi) => ({
        ...response,
        hourlyRate: transformMoneyFromAPIToMoney(response.hourlyRate),
      }),
      providesTags: (_, __, arg) => [{ type: tags.entry, id: arg }],
    }),
    getHourTypes: builder.query<HourType[], void>({
      query: () => ({
        url: "/hourType.list",
      }),
      providesTags: [tags.list],
    }),
  }),
  overrideExisting: false,
});

export const { useGetHourTypeQuery, useGetHourTypesQuery } = endpoints;
