import { Footer, Page, SkeletonLoader } from "@simplicate/ui";
import {
  fontSize7,
  fontSize8,
  lineHeight5,
  lineHeight2,
  fontSize4,
  size12,
  size7,
  size10,
  size8,
  size6,
} from "@simplicate-software/design-tokens";
import { ProjectServicePageTemplate } from "./ProjectServicePage.template";
import styles from "./ProjectServicePageSkeleton.module.scss";

type ProjectServicePageSkeletonProps = {
  testId?: string;
};

export const ProjectServicePageSkeleton = ({ testId }: ProjectServicePageSkeletonProps) => {
  return (
    <Page
      footer={
        <Footer>
          <SkeletonLoader fontSize={fontSize8} width={size7} />

          <SkeletonLoader fontSize={fontSize8} width={size7} />
        </Footer>
      }
      testId={testId}
    >
      <ProjectServicePageTemplate>
        <ProjectServicePageTemplate.Alerts>
          <SkeletonLoader fontSize={fontSize8} />
        </ProjectServicePageTemplate.Alerts>

        <ProjectServicePageTemplate.Title>
          <SkeletonLoader fontSize={fontSize8} width={size12} />
        </ProjectServicePageTemplate.Title>

        <ProjectServicePageTemplate.Section>
          <ProjectServicePageTemplate.PropertiesBlock>
            <ProjectServicePageTemplate.Title>
              <SkeletonLoader fontSize={fontSize7} width={size10} />
            </ProjectServicePageTemplate.Title>

            <ProjectServicePageTemplate.PropertiesBlockColumn>
              <div className={styles.column}>
                <div className={styles.item}>
                  <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size8} />
                  <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                </div>

                <div className={styles.item}>
                  <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size8} />
                  <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                </div>

                <SkeletonLoader fontSize={fontSize4} />

                <div className={styles.split}>
                  <div className={styles.item}>
                    <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size6} />
                    <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                  </div>

                  <div className={styles.item}>
                    <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size6} />
                    <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                  </div>
                </div>
              </div>
            </ProjectServicePageTemplate.PropertiesBlockColumn>

            <ProjectServicePageTemplate.PropertiesBlockColumn>
              <div className={styles.column}>
                <div className={styles.item}>
                  <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size8} />
                  <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                </div>

                <div className={styles.item}>
                  <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size8} />
                  <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                </div>
              </div>
            </ProjectServicePageTemplate.PropertiesBlockColumn>
          </ProjectServicePageTemplate.PropertiesBlock>

          <ProjectServicePageTemplate.OptionsBlock>
            <ProjectServicePageTemplate.Title>
              <SkeletonLoader fontSize={fontSize7} width={size10} />
            </ProjectServicePageTemplate.Title>

            <ProjectServicePageTemplate.OptionsBlockColumn>
              <div className={styles.column}>
                <div className={styles.item}>
                  <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size8} />
                  <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                </div>

                <div className={styles.item}>
                  <SkeletonLoader fontSize={fontSize4} lineHeight={lineHeight2} width={size8} />
                  <SkeletonLoader fontSize={fontSize8} lineHeight={lineHeight5} />
                </div>
              </div>
            </ProjectServicePageTemplate.OptionsBlockColumn>
          </ProjectServicePageTemplate.OptionsBlock>
        </ProjectServicePageTemplate.Section>
      </ProjectServicePageTemplate>
    </Page>
  );
};
