import { type MoneyFromAPI } from "@simplicate/api-client";
import { CurrencyFormat } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import styles from "./ServiceGroupFooter.module.scss";

type ServiceGroupFooterProps = {
  total?: MoneyFromAPI;
};
export const ServiceGroupFooter = ({ total }: ServiceGroupFooterProps) => {
  const { t } = useTranslation("grouped_services_manager");

  const amount = Number(total?.amount) > 0 ? total?.amount : undefined;

  return (
    <div className={styles.footer} data-testid="services-group-footer">
      <div className={styles.label}>{t("total")}</div>
      <div className={styles.total}>{amount ? <CurrencyFormat value={amount} /> : "€ -"}</div>
    </div>
  );
};
