import { Button, Icon, SUPPORTED_ICONS } from "@simplicate/ui";
import { MessageBus } from "@simplicate/utils";
import { useEffect, useState } from "react";

type SupportedIcon = (typeof SUPPORTED_ICONS)[number];

function isSupportedIcon(icon?: string): icon is SupportedIcon {
  return icon !== undefined && (SUPPORTED_ICONS as string[]).includes(icon);
}

export const MessageBusActionButtons = () => {
  const [buttons, setButtons] = useState<ActionButtonConfig[]>([]);

  useEffect(() => MessageBus.subscribe("registerActionButton", setButtons), []);

  return buttons.map((config) => {
    switch (config.type) {
      case "button":
        return (
          <Button
            key={config.eventName}
            variant={config.variant}
            onClick={() => MessageBus.publish("actionButtonEvent", { eventName: config.eventName })}
            disabled={config.disabled}
          >
            {isSupportedIcon(config.icon) ? <Icon icon={config.icon} fixedWidth /> : undefined}
            {config.text}
          </Button>
        );

      case "link":
        return (
          <Button
            key={config.href}
            to={config.href}
            target={config.isDownload ? "_blank" : "_self"}
            rel={config.isDownload ? "noopener noreferrer" : ""}
            variant={config.variant}
            disabled={config.disabled}
          >
            {isSupportedIcon(config.icon) ? <Icon icon={config.icon} fixedWidth /> : undefined}
            {config.text}
          </Button>
        );
    }
  });
};
