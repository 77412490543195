import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LegacyWebComponent, RouteErrorBoundary, VersionPage } from "../components";
import { RedirectToFirstAccessiblePage } from "./components";
import {
  businessIntelligenceRoutes,
  crmRoutes,
  dashboardRoutes,
  environmentManagementRoutes,
  hoursRoutes,
  hrmRoutes,
  insightsRoutes,
  invoicingRoutes,
  projectsRoutes,
  resourcePlannerRoutes,
  salesRoutes,
} from "./moduleRoutes";
import { RootComponent } from "./RootComponent";

const router = createBrowserRouter([
  {
    // This top-level route does not have a path property because it is a "layout path".
    element: <RootComponent />,
    errorElement: <RouteErrorBoundary />,
    children: [
      // Entry point
      { path: "/", element: <RedirectToFirstAccessiblePage to="/dashboard/my-tasks" /> },

      // Module routes
      ...dashboardRoutes,
      ...crmRoutes,
      ...hrmRoutes,
      ...salesRoutes,
      ...projectsRoutes,
      ...resourcePlannerRoutes,
      ...hoursRoutes,
      ...invoicingRoutes,
      ...businessIntelligenceRoutes,
      ...insightsRoutes,
      ...environmentManagementRoutes,

      { path: "version", element: <VersionPage /> },

      // Fallback component
      { path: "*", element: <LegacyWebComponent /> },
    ],
  },
]);

export const MainRouterProvider = () => <RouterProvider router={router} />;
