import { useGetRevenueGroupsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Select, Tooltip } from "@simplicate/ui";
import { memo } from "react";

type RevenueGroupSelectProps = {
  value: string | undefined;
  touched?: boolean;
  error: string | undefined;
  onSelect: (id: string) => void;
  disabled?: boolean;
  tooltip?: string;
};

export const RevenueGroupSelect = memo(function RevenueGroupSelect({
  error,
  touched = false,
  disabled,
  tooltip,
  ...props
}: RevenueGroupSelectProps) {
  const { t } = useTranslation("project_services");

  const { data: options } = useGetRevenueGroupsQuery();

  const compatibleOptions = options?.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const select = (
    <Select
      testId="revenue-group-select"
      name="revenueGroup"
      label={t("revenue_group")}
      placeholder={t("revenue_group_placeholder")}
      emptyMessage={t("no_revenue_groups")}
      options={compatibleOptions}
      invalid={touched && error !== undefined}
      disabled={disabled}
      filter={false}
      {...props}
    />
  );

  return tooltip ? (
    <Tooltip message={tooltip} position="down">
      {select}
    </Tooltip>
  ) : (
    select
  );
});
