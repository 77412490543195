import { size4 } from "@simplicate-software/design-tokens";
import classNames from "classnames";
import { Column, ColumnProps } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import {
  DataTable,
  DataTablePropsMultiple,
  DataTableSelectionMultipleChangeEvent,
  DataTableValueArray,
} from "primereact/datatable";
import { Row } from "primereact/row";
import { Children, ReactElement, ReactNode, useRef } from "react";
import { Checkbox } from "../Checkbox";
import { Icon } from "../Icon";
import { PAGE_STICKY_TOP } from "../Page";
import { Portal } from "../Portal";
import { usePortalContext } from "../PortalProvider/PortalProvider";
import { buildColumnProps } from "./Column";
import { CustomThead } from "./CustomThead";
import { StickyHeaderOptions, useStickyHeader } from "./hooks/useStickyHeader";
import styles from "./Table.module.scss";

type TableProps<TValue extends DataTableValueArray> = Partial<
  DataTablePropsMultiple<TValue> & {
    children: Required<ReactElement<ColumnProps> | ReactElement<ColumnProps>[]>;
    collectiveActions: ReactNode;
    reorderable: boolean;
    selectable: boolean;
    selectAllComponent: ReactNode;
    selectionMode: "multiple";
    onSelectionChange: (e: DataTableSelectionMultipleChangeEvent<TValue>) => void;
    toggleSelectionOfRecord: (record: TValue[number]) => void;
    selection: TValue;
    testId: string;
    disabled: boolean;
    enableStickyHeader: boolean;
    stickyHeaderMargin?: StickyHeaderOptions["margin"];
  }
> & { value: TValue };

// eslint-disable-next-line complexity -- The Table component has some nested if statements related to selectable that give it a higher than usual complexity
export const Table = <TValue extends DataTableValueArray>({
  value,
  children = [],
  testId,
  reorderable = false,
  selectable = false,
  collectiveActions,
  selectAllComponent,
  toggleSelectionOfRecord,
  disabled,
  enableStickyHeader = false,
  stickyHeaderMargin,
  ...props
}: TableProps<TValue>) => {
  if (selectable) {
    if (!selectAllComponent || !props.onSelectionChange || props.selection === null || props.selection === undefined) {
      throw new Error(
        "Invalid configuration - the useTableSelection hook will provide all the necessary props for selection",
      );
    }
  }

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { visible, width } = useStickyHeader(tableContainerRef, {
    enabled: enableStickyHeader,
    margin: stickyHeaderMargin,
  });

  const { targetRefs } = usePortalContext();

  return (
    <div ref={tableContainerRef} style={{ width: "100%" }}>
      {targetRefs && visible && (
        <Portal targetRef={targetRefs[PAGE_STICKY_TOP]}>
          <CustomThead width={width}>{children}</CustomThead>
        </Portal>
      )}

      {/* @ts-expect-error -- This thing cannot differentiate between selectionmodes when we only need multiple. */}
      <DataTable
        {...props}
        value={value}
        className={classNames(styles.table, disabled && styles.disabled)}
        data-testid={testId}
        disabled={disabled}
        sortIcon={
          /* istanbul ignore next*/ ({ sorted, sortOrder }) => (
            <Icon
              icon="sort"
              variant={sorted ? "sorted" : "default"}
              flip={sortOrder === 1 ? "vertical" : undefined}
              className={classNames(styles.sortIcon, sorted && styles.sorted)}
            />
          )
        }
        reorderableRows={reorderable}
        headerColumnGroup={
          <ColumnGroup>
            <Row>
              {selectable && <Column {...buildColumnProps()} style={{ width: size4 }} header={selectAllComponent} />}
              {children &&
                Children.map(children, (child, index) => {
                  if (!child) {
                    throw new Error("All children of the Table component must be defined.");
                  }

                  return index === 0 ? (
                    <Column
                      {...child.props}
                      colSpan={reorderable ? 2 : 1}
                      header={
                        /* istanbul ignore next */ (...args) => {
                          if (selectable && (props.selection?.length ?? 0) > 0 && collectiveActions) {
                            return collectiveActions;
                          }

                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call -- This is a valid call, just not typed properly
                          return typeof child.props.header === "function"
                            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access -- This is the same contract PrimeReact's DataTable has with its cell contents
                              child.props.header(...args)
                            : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- It doesn't matter what this is, it will be interpreted as a ReactNode anyway
                              child.props.header;
                        }
                      }
                    />
                  ) : (
                    <Column {...child.props} />
                  );
                })}
            </Row>
          </ColumnGroup>
        }
      >
        {selectable && (
          <Column
            {...buildColumnProps()}
            bodyClassName={styles.selectColumn}
            align="center"
            style={{ width: size4 }}
            body={
              /* istanbul ignore next */ (rowData: TValue[number]) => (
                <Checkbox
                  value={props.selection?.includes(rowData) ?? false}
                  onChange={() => toggleSelectionOfRecord?.(rowData)}
                />
              )
            }
          />
        )}
        {reorderable && (
          <Column
            bodyClassName={styles.reorderDragHandleCell}
            align="right"
            rowReorder
            rowReorderIcon={
              /* istanbul ignore next */ ({ iconProps: { className, ...iconProps } }) => {
                return (
                  // @ts-expect-error -- the ref is not typed correctly
                  <div {...iconProps} className={classNames(className, styles.dragHandle)}>
                    <Icon icon="grip" />
                  </div>
                );
              }
            }
          />
        )}
        {children}
      </DataTable>
    </div>
  );
};
