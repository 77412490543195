import { SimplicateSettings, useGetSimplicateSettingsQuery } from "@simplicate/api-client";
import { useTranslation } from "@simplicate/translations";
import { Button, showToast } from "@simplicate/ui";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { EnvironmentNotification } from "./EnvironmentNotification/EnvironmentNotification";

export const selectSimplicateSettings = (result: SimplicateSettings | undefined) => {
  return {
    payment: {
      showPaymentMessage: result?.showPaymentMessage ?? false,
      paymentMessage: result?.paymentMessage,
    },
    hasAcceptedGdpr: result?.hasAcceptedGdpr ?? 1,
  };
};

export const useEnvironmentNotifications = () => {
  const { t } = useTranslation("environment_notifications");
  const location = useLocation();

  const {
    payment: { showPaymentMessage, paymentMessage },
    hasAcceptedGdpr,
  } = useGetSimplicateSettingsQuery(undefined, {
    selectFromResult: /* istanbul ignore next -- RTK is mocked in test */ ({ data }) => selectSimplicateSettings(data),
  });

  useEffect(() => {
    if (hasAcceptedGdpr !== 0) {
      return;
    }
    showToast({
      content: (
        <EnvironmentNotification
          message={t("accept_gdpr_notification")}
          button={<Button to="/settings/subscription">{t("go_to_account_settings")}</Button>}
        />
      ),
      width: "wide",
      options: {
        duration: 0,
        toastId: "environment-notification-gdpr",
      },
    });
  }, [hasAcceptedGdpr, location.pathname, t]);

  useEffect(() => {
    if (!showPaymentMessage || paymentMessage === undefined) {
      return;
    }

    showToast({
      content: <EnvironmentNotification message={paymentMessage} />,
      width: "wide",
      options: {
        duration: 0,
        toastId: "environment-notification-payment-message",
      },
    });
  }, [showPaymentMessage, paymentMessage, location.pathname]);
};
