import { Big } from "@simplicate/api-client";
import { useNumericFormatter } from "@simplicate/number-format";
import { useTranslation } from "@simplicate/translations";
import { Alert, Button } from "@simplicate/ui";
import type { MoneyInForm } from "./types";

type ProjectServiceTotalInfoProps = {
  calculatedTotal: Big;
  invoicePrice: MoneyInForm | undefined;
  setInvoicePrice: (value: Big) => void;
};

export const ProjectServiceTotalInfo = ({
  calculatedTotal,
  invoicePrice,
  setInvoicePrice,
}: ProjectServiceTotalInfoProps) => {
  const { t } = useTranslation("project_services");
  const formattedCalculatedTotal = useNumericFormatter(calculatedTotal?.toString());

  if (!invoicePrice?.amount) {
    return;
  }

  if (calculatedTotal.cmp(invoicePrice.amount) === 0) {
    return;
  }

  return (
    <Alert type="info">
      <span>
        {t("invoice_price_differs_from_calculated", {
          value: formattedCalculatedTotal,
        })}
      </span>
      <Button
        data-testid="reset-invoice-price-button"
        name="reset_invoice_price"
        onClick={() => setInvoicePrice(calculatedTotal)}
        variant="subtle"
        type="button"
      >
        {t("reset_invoice_price")}
      </Button>
    </Alert>
  );
};
