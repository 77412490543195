import {
  InvoiceMethod,
  transformMoneyToMoneyFromAPI,
  Big,
  ProjectServiceBody,
  EditProjectServiceBody,
} from "@simplicate/api-client";
import { format } from "date-fns";
import { transformToCreateServiceCostType } from "./transformCostTypes";
import { transformToCreateServiceHourType } from "./transformHourTypes";
import type { ValidProjectServiceForm } from "./types";

const transformHourTypeValuesToHourTypeConfiguration = ({
  canRegisterHours,
  hourTypes,
  hourTypesSpecifiedTotal,
  invoiceMethod,
}: Pick<ValidProjectServiceForm, "canRegisterHours" | "hourTypes" | "hourTypesSpecifiedTotal" | "invoiceMethod">) => {
  if (!canRegisterHours) {
    return null;
  }

  return {
    hourTypes: hourTypes?.map((hourType) => transformToCreateServiceHourType(hourType, invoiceMethod)) ?? [],
    employeeHourlyRates: [],
    specifiedTotal: transformMoneyToMoneyFromAPI(hourTypesSpecifiedTotal ?? { currency: "EUR", amount: Big(0) }),
  };
};

const transformTimeframe = ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
  if (!startDate && !endDate) {
    return null;
  }

  return {
    startDate: startDate ? format(startDate, "yyyy-MM-dd") : null,
    endDate: endDate ? format(endDate, "yyyy-MM-dd") : null,
  };
};

// eslint-disable-next-line complexity -- This function transforms a lot of data. It's easier to read when it's not split up.
const transformFormToProjectServiceBody = (
  values: ValidProjectServiceForm,
  hasResourcePlanner: boolean,
): ProjectServiceBody => {
  let body: ProjectServiceBody = {
    defaultServiceId: values.defaultService,
    invoiceMethod: values.invoiceMethod,
    timeFrame: transformTimeframe(values.timeframe),
    description: values.description,
    explanation: values.explanation || null,
    revenueGroupId: values.revenueGroup,
    vatCodeId: values.vatCode,
    canRegisterHours: values.canRegisterHours,
    canRegisterCosts: values.canRegisterCosts,
    hourTypeConfiguration: transformHourTypeValuesToHourTypeConfiguration({
      canRegisterHours: values.canRegisterHours,
      hourTypes: values.hourTypes,
      hourTypesSpecifiedTotal: values.hourTypesSpecifiedTotal,
      invoiceMethod: values.invoiceMethod,
    }),
    isPlannable: hasResourcePlanner ? values.isPlannable ?? false : undefined,
    costTypes:
      values.canRegisterCosts && values.costTypes ? values.costTypes.map(transformToCreateServiceCostType) : [],
  };

  if (values.invoiceMethod === InvoiceMethod.fixed_price) {
    body = {
      ...body,
      invoiceInInstallments: values.invoiceInInstallments ?? false,
      invoiceableFrom: values.invoiceableFrom ? format(values.invoiceableFrom, "yyyy-MM-dd") : null,
      invoicePrice: transformMoneyToMoneyFromAPI({
        amount: values.invoicePrice.amount,
        currency: values.invoicePrice.currency || "EUR",
      }),
    };
  }

  if (values.invoiceMethod !== InvoiceMethod.time_and_expenses) {
    body.costTypes = body.costTypes.map((costType) => ({
      ...costType,
      isInvoiceable: undefined,
    }));
  }

  return body;
};

export const transformFormToCreateProjectServiceBody = (
  values: ValidProjectServiceForm,
  projectId: string,
  hasResourcePlanner: boolean,
) => ({
  ...transformFormToProjectServiceBody(values, hasResourcePlanner),
  projectId,
});

export const transformFormToEditProjectServiceBody = (
  values: ValidProjectServiceForm,
  serviceId: string,
  hasResourcePlanner: boolean,
): EditProjectServiceBody => ({
  ...transformFormToProjectServiceBody(values, hasResourcePlanner),
  id: serviceId,
});
