import {
  Big,
  HourTypeInCreateServiceBody,
  HourTypeInService,
  InvoiceMethod,
  transformMoneyToMoneyFromAPI,
} from "@simplicate/api-client";
import { HourTypeInForm, ValidHourTypeInForm } from "./types";

export const transformToCreateServiceHourType = (
  hourTypeInForm: ValidHourTypeInForm,
  invoiceMethod: InvoiceMethod,
): HourTypeInCreateServiceBody => ({
  defaultHourTypeId: hourTypeInForm.id,
  hourlyRate: transformMoneyToMoneyFromAPI(hourTypeInForm.hourlyRate),
  amountOfHours: hourTypeInForm.amount,
  isInvoiceable: invoiceMethod === InvoiceMethod.time_and_expenses ? hourTypeInForm.isInvoiceable : undefined,
});

export const transformToHourTypeInForm = (hourTypeInService: HourTypeInService): HourTypeInForm => ({
  id: hourTypeInService.defaultHourType.id,
  name: hourTypeInService.defaultHourType.name,
  amount: hourTypeInService.amountOfHours,
  isInvoiceable: true,
  hourlyRate: {
    amount: Big(hourTypeInService.hourlyRate.amount),
    currency: hourTypeInService.hourlyRate.currency,
  },
  total: {
    amount: Big(hourTypeInService.hourlyRate.amount).mul(hourTypeInService.amountOfHours),
    currency: hourTypeInService.hourlyRate.currency,
  },
  hasRegistrations: hourTypeInService.hasRegistrations,
});
