import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import styles from "./TextArea.module.scss";

type TextAreaProps = Omit<InputHTMLAttributes<HTMLTextAreaElement>, "value"> & {
  name: string;
  label: string;
  testId?: string;
  hasError?: boolean;
  disabled?: boolean;
  value?: string;
};

export const TextArea = ({
  testId,
  name,
  label,
  value = "",
  hasError = false,
  disabled = false,
  className,
  ...props
}: TextAreaProps) => {
  return (
    <div
      className={classNames(styles.wrapper, disabled && styles.disabled, className)}
      data-testid={`inputWrapper-${testId}`}
    >
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      )}
      <div
        data-testid={testId && `${testId}-wrapper`}
        className={classNames(styles.inputWrapper, hasError && styles.invalid)}
      >
        <textarea
          id={name}
          name={name}
          value={value ?? ""}
          className={styles.input}
          data-testid={testId}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
};
