import { skipToken, useFeatureFlag, useGetProjectQuery } from "@simplicate/api-client";
import { postIframeMessage, useAppDispatch } from "@simplicate/state";
import { useTranslation } from "@simplicate/translations";
import { ActionDropdown, Icon } from "@simplicate/ui";
import { colorCommonGrey300, colorCommonGrey700, lightColorIconInvert } from "@simplicate-software/design-tokens";
import { useParams } from "react-router-dom";
import { ProjectDetailsPageTabs } from "../ProjectDetailsPageTabs";

export const ProjectDetailsServicePageTabs = () => {
  const { t } = useTranslation("project_services");
  const { id } = useParams<{ id: string }>();

  const { enabled } = useFeatureFlag("projects-new-service-ui");
  const { data } = useGetProjectQuery(id ?? skipToken);
  const isEmployeeHourlyRate = data?.data?.hours_rate_type === "employee_tariff";

  const dispatch = useAppDispatch();

  const openLegacyPopup = () => {
    dispatch(postIframeMessage({ eventName: "services_add" }));
  };

  return (
    <ProjectDetailsPageTabs
      actionButtons={
        enabled && (
          <ActionDropdown
            button={
              <ActionDropdown.Button testId="new-service-dropdown-button">
                {t("service_add")}
                <Icon icon="chevronDown" color={lightColorIconInvert} />
              </ActionDropdown.Button>
            }
            testId="new-service-dropdown"
          >
            <ActionDropdown.Action
              disabled={isEmployeeHourlyRate}
              tooltip={isEmployeeHourlyRate ? t("service_add_employee_hourly_rate_disabled") : undefined}
              to={`/projects/${id}/services/addservice`}
              testId="new-service-button"
            >
              <Icon icon="stars" color={isEmployeeHourlyRate ? colorCommonGrey300 : colorCommonGrey700} />
              {t("service_add_new")}
            </ActionDropdown.Action>

            <ActionDropdown.Action onClick={openLegacyPopup} testId="legacy-service-button">
              <Icon icon="plus" />
              {t("service_add_legacy")}
            </ActionDropdown.Action>
          </ActionDropdown>
        )
      }
    />
  );
};
