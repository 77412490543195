import { useMemo } from "react";
import { License, LicenseConfig, useGetLicenseQuery } from "../../endpoints";

type FeatureConfig =
  | {
      requireAll: readonly License[];
    }
  | {
      requireSome: readonly License[];
    };

export type LicensedFeature = keyof typeof LICENSED_FEATURES;

// TODO: remove all references to "professional" and "ultimate_efficiency" when those priceplans are removed
const LICENSED_FEATURES = {
  crm_price_agreements: { requireSome: ["professional", "ultimate_efficiency", "tariff_agreements"] },
  dashboard_module: { requireSome: ["professional", "ultimate_efficiency", "workflowmanagement"] },
  hours_planning: { requireSome: ["professional", "ultimate_efficiency", "planning"] },
  hours_project_approval: { requireSome: ["professional", "ultimate_efficiency", "hours_approval"] },
  hours_reporting: { requireSome: ["professional", "ultimate_efficiency", "advanced_reporting"] },
  hours_weekly_approval: { requireSome: ["professional", "ultimate_efficiency", "hours_approval"] },
  hrm_additional_options: { requireAll: ["hrm_options"] },
  hrm_reporting: { requireSome: ["professional", "ultimate_efficiency", "advanced_reporting"] },
  invoicing_accounting_app: { requireSome: ["professional", "ultimate_efficiency", "accountingapp"] },
  invoicing_invoice_approval: { requireAll: ["invoice_options"] },
  invoicing_mrr_reporting: { requireSome: ["professional", "ultimate_efficiency", "advanced_reporting"] },
  invoicing_reverse_billing: { requireAll: ["invoice_options"] },
  projects_project_purchasing: { requireAll: ["purchase_costs"] },
  projects_reporting: { requireSome: ["professional", "ultimate_efficiency", "advanced_reporting"] },
  workflows: { requireSome: ["professional", "ultimate_efficiency", "workflowmanagement"] },
} satisfies Record<string, FeatureConfig>;

function licenseIsEnabled(license?: LicenseConfig): boolean {
  return license ? license.value === "1" : false;
}

export const useLicenseGuard = (feature: LicensedFeature): { enabled: boolean; isLoading: boolean } => {
  const { data, isLoading } = useGetLicenseQuery();

  const enabled = useMemo(() => {
    if (!data?.licention) {
      return false;
    }

    const config = LICENSED_FEATURES[feature];

    const satisfiesRequireSome =
      "requireSome" in config && config.requireSome.some((license) => licenseIsEnabled(data.licention[license]));
    const satisfiesRequireAll =
      "requireAll" in config && config.requireAll.every((license) => licenseIsEnabled(data.licention[license]));

    return satisfiesRequireAll || satisfiesRequireSome;
  }, [data, feature]);

  return {
    enabled,
    isLoading,
  };
};
