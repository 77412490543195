import classNames from "classnames";
import { type PropsWithChildren } from "react";
import { Clickable, type ClickableProps } from "../Clickable";
import styles from "./Button.module.scss";

export type ButtonProps = PropsWithChildren<
  ClickableProps & {
    ariaLabel?: string;
    variant?: "danger" | "invisible" | "primary" | "secondary" | "subtle";
    size?: "medium" | "small";
    disabled?: boolean;
    hasInlinePadding?: boolean;
  }
>;

export const Button = ({
  ariaLabel,
  disabled = false,
  testId,
  variant = "primary",
  size = "medium",
  children,
  hasInlinePadding = true,
  ...clickableProps
}: ButtonProps) => {
  return (
    <Clickable
      aria-label={ariaLabel}
      className={classNames(
        styles.reset,
        styles.pill,
        styles[variant],
        styles[size],
        hasInlinePadding && styles.hasInlinePadding,
        disabled && styles.disabled,
      )}
      disabled={disabled}
      testId={testId}
      {...clickableProps}
    >
      {children}
    </Clickable>
  );
};
